import { useState, useEffect } from "react";
import { BarChart } from "@mui/x-charts/BarChart";
import { useAuth0 } from "@auth0/auth0-react";
import { setAccessToken } from "../utils/auth";
import { getPaymentStats } from "../utils/api";

export default function PaymentStats() {
  const { getAccessTokenSilently } = useAuth0();

  const [dailyPayments, setDailyPayments] = useState<{ _id: string; amount: number }[]>([]);
  const [weeklyPayments, setWeeklyPayments] = useState<{ _id: string; amount: number; year: string; week: string }[]>([]);
  const [monthlyPayments, setMonthlyPayments] = useState<{ _id: string; amount: number }[]>([]);

  useEffect(() => {
    constructUserStat();
  }, []);

  const constructUserStat = async () => {
    const token = await getAccessTokenSilently();
    setAccessToken(token);

    const res = await getPaymentStats();

    if (res.isOk) {
      setDailyPayments(res.dailyPaidAmount);
      setWeeklyPayments(res.weeklyPaidAmount);
      setMonthlyPayments(res.monthlyPaidAmount);
    }
  };
  return (
    <div style={{ width: "100%", height: "100%" }}>
      <h1 style={{ margin: "1rem 0" }}>Daily 매출 현황 (최근 30일)</h1>
      <BarChart
        loading={dailyPayments.length === 0}
        dataset={dailyPayments}
        xAxis={[
          {
            scaleType: "band",
            dataKey: "_id",
            valueFormatter: (value: string) => {
              return `${value.slice(0, 4)}\n${value.slice(5)}`;
            },
          },
        ]}
        series={[{ dataKey: "amount" }]}
        width={1500}
        height={500}
        barLabel={(item, context) => {
          return item.value?.toLocaleString();
        }}
        colors={["#c8d1c0"]}
        axisHighlight={{ x: "band", y: "line" }}
        sx={{
          fontSize: "1rem",
        }}
        grid={{
          vertical: true,
          horizontal: true,
        }}
      />
      <h1 style={{ margin: "1rem 0" }}>Weekly 매출 현황 (최근 20주)</h1>
      <BarChart
        loading={weeklyPayments.length === 0}
        dataset={weeklyPayments}
        xAxis={[
          {
            scaleType: "band",
            dataKey: "_id",
            valueFormatter: (value: string) => {
              return `${value.slice(0, 4)}\n${value.slice(5)}`;
            },
          },
        ]}
        series={[{ dataKey: "amount" }]}
        width={1200}
        height={500}
        barLabel={(item, context) => {
          return item.value?.toLocaleString();
        }}
        colors={["#c6d4e6"]}
        axisHighlight={{ x: "band", y: "line" }}
        sx={{
          fontSize: "1rem",
        }}
        grid={{
          vertical: true,
          horizontal: true,
        }}
      />
      <h1 style={{ margin: "1rem 0" }}>Monthly 매출 현황 (최근 12개월)</h1>
      <BarChart
        loading={monthlyPayments.length === 0}
        dataset={monthlyPayments}
        xAxis={[
          {
            scaleType: "band",
            dataKey: "_id",
            valueFormatter: (value: string) => {
              return `${value.slice(0, 4)}\n${value.slice(5)}`;
            },
          },
        ]}
        series={[{ dataKey: "amount" }]}
        width={1000}
        height={500}
        barLabel={(item, context) => {
          return item.value?.toLocaleString();
        }}
        colors={["#e3cb9e"]}
        axisHighlight={{ x: "band", y: "line" }}
        sx={{
          fontSize: "1rem",
        }}
        grid={{
          vertical: true,
          horizontal: true,
        }}
      />
    </div>
  );
}
