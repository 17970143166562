import { useAuth0 } from '@auth0/auth0-react';
import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import {getNewsletterStats, getUserStats} from '../utils/api';
import { setAccessToken } from '../utils/auth';

import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import RefreshIcon from '@mui/icons-material/Refresh';
import CircularProgress from "@mui/material/CircularProgress";
import {green} from "@mui/material/colors";
import {MainList} from "./MainList";

let newsletterList: any = [];

export const NewsletterStatistics = () => {
  const [loading, setLoading] = useState(false);
  const [newsletter, setNewsletter] = useState<any>([]);
  // const [newsletter, setNewsletter] = useState<any>({ labels: [], datasets: [] });
  const [items, setItems] = useState<any>([]);
  const { getAccessTokenSilently } = useAuth0();

  const convertChartData = (dataOjb: any, rgba: string) => {
    const chartLabels = dataOjb.map((item: any) => item.title || item._id);
    const chartData = dataOjb.map((item: any) => item.count);

    // let chartAccumulator : any = [];
    //
    // chartData.reduce((accumulator: number, currentValue: number, currentIndex: number, array: any)=>{
    //     chartAccumulator.push(accumulator + currentValue);
    //     return accumulator + currentValue;
    // }, 0);

    const data = {
      labels: chartLabels,
      datasets: [
        {
          type: "bar",
          label: "구독자수",
          backgroundColor: rgba || "rgba(143, 120, 75, 0.5)",
          // backgroundColor: '#a',
          // borderColor: 'darkgray',
          // borderWidth: 1,
          data: chartData,
          yAxisID: "y-axis-1",
        },
      ],
    };
    return data;
  };

  const constructNewsletterStat = async () => {
    setAccessToken(await getAccessTokenSilently());
    setLoading(true);
    const newsletterStats = await getNewsletterStats();

    const newsletterChart = convertChartData(newsletterStats.newsletter, "");

    setLoading(false);

    console.log(">>>", newsletterChart);
    setNewsletter(newsletterChart);

    newsletterList = newsletterStats.newsletter;

    setItems(
      newsletterList.map((item: any, index: number) => {
        return {
          ...item,
        };
      })
    );
  };

  const options = {
    scales: {
      // yAxes: [
      y: [
        {
          type: "linear",
          display: true,
          position: "left",
          id: "y-axis-1",
        },
      ],
    },
  };

  const sort = (col: any) => {
    const index = columnInfo.findIndex((e) => e.name === col.name);
    columnInfo[index].isAscending = !columnInfo[index].isAscending;
    const sortedItems = [...newsletterList].sort((a: any, b: any) => {
      if (typeof a[col.name] === "number" || typeof a[col.name] === "boolean") {
        return columnInfo[index].isAscending ? a[col.name] - b[col.name] : b[col.name] - a[col.name];
      }

      if (!a[col.name]) {
        a[col.name] = "";
      }
      if (!b[col.name]) {
        b[col.name] = "";
      }

      return columnInfo[index].isAscending ? a[col.name].localeCompare(b[col.name]) : b[col.name].localeCompare(a[col.name]);
    });
    newsletterList = sortedItems;
    // setItems(sortedItems);
    // constructList();

    const newsletterChart = convertChartData(newsletterList, "");
    setNewsletter(newsletterChart);

    setItems(
      newsletterList.map((item: any, index: number) => {
        return {
          ...item,
          // orderNo: userCount - index,
          // isWithdrawn: item.isWithdrawn ? 'Yes' : '-',
          // dateOfBirth: moment(item.dateOfBirth).format('YYYY.MM.DD'),
          // createdAt: moment(item.createdAt).format('YYYY.MM.DD HH:mm:ss'),
        };
      })
    );
  };

  const columnInfo = [
    {
      label: "뉴스레터명",
      name: "title",
      isAscending: false,
      isTextEditable: false,
      style: { minWidth: 120 },
    },
    {
      label: "구독자수",
      name: "count",
      isAscending: true,
      isTextEditable: false,
      style: { minWidth: 120 },
    },
    {
      label: "내용",
      name: "shortDescription",
      isAscending: true,
      isTextEditable: false,
      style: { minWidth: 200 },
    },

    {
      label: "좋아요 수",
      name: "likedSum",
      isAscending: true,
      isTextEditable: false,
      style: { minWidth: 120 },
    },

    {
      label: "노출일시",
      name: "visibledAt",
      isAscending: true,
      isTextEditable: false,
      style: { minWidth: 120 },
    },

    {
      label: "노출여부",
      name: "visible",
      isAscending: true,
      isTextEditable: false,
      style: { minWidth: 120 },
    },
    {
      label: "등록일",
      name: "createdAt",
      isAscending: true,
      isTextEditable: false,
      style: { minWidth: 120 },
    },
  ];

  useEffect(() => {
    constructNewsletterStat();
  }, []);

  if (newsletter.length === 0) {
    return <h1>Loading...</h1>;
  }

  console.log(newsletter);
  return (
    <div>
      <h1>뉴스레터별 구독자 현황 (구독자 10명 이상)</h1>
      <div style={{ width: "1800px" }}>
        <Bar data={newsletter} options={options as any} />
      </div>
      <hr />
      <div style={{ width: "1200px", height: "800px" }}>
        <MainList onSortToggle={sort} columnInfo={columnInfo} listData={items} buttons={[]} />
      </div>

      <Box sx={{ "& > :not(style)": { m: 1 } }} style={{ position: "fixed", top: "70px", left: "950px" }}>
        <Fab color="primary" aria-label="refresh">
          <RefreshIcon onClick={constructNewsletterStat} />
        </Fab>
        {loading && (
          <CircularProgress
            size={68}
            sx={{
              color: green[500],
              position: "absolute",
              top: -6,
              left: -6,
              zIndex: 1,
            }}
          />
        )}
      </Box>
    </div>
  );
};
